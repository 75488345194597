import React, { useEffect } from 'react';
import Phaser from 'phaser';

//array of images
const images = [
    'https://cdn.shopify.com/s/files/1/0125/8261/7145/products/UYPfMMYHwSAmVJT_418479773_1000x.png.webp?v=1592498835',
    'https://media.discordapp.net/attachments/790456780735119391/1068083037558018088/1-26-2023_leighbeeton_dnd_fancy_transparent2.png?width=699&height=701',
    'https://media.discordapp.net/attachments/790456780735119391/1013716227706847263/bapbap_dog.png?width=879&height=701',
    'https://cdn.discordapp.com/attachments/790456780735119391/880743087553519626/bapbap_lup.png',
    'https://cdn.discordapp.com/attachments/790456780735119391/880733576482676766/trans.png',
    'https://cdn.discordapp.com/attachments/790456780735119391/883268496325242900/garith_11.png',
];

const PhaserComponent = () => {
    useEffect(() => {
        const config = {
            type: Phaser.AUTO,
            //width of screen
            width: window.innerWidth,
            //height of screen
            height: window.innerHeight,
            //background transparent
            transparent: true,
            //debug mode

            scene: {
                preload: preload,
                create: create,
                update: update,


            },
            physics: {
                default: 'matter',
                matter: {
                    gravity: {
                        y: 0.8
                    },
                    //debug: true,
                }
            }



        };


        const game = new Phaser.Game(config);

        function preload() {
            // Load images in images variable

            //ball image

            //load images
            images.forEach((image) => {
                this.load.image(image, image);
            });



        }

        function create() {
            // Create game objects here
            const group = this.add.group();

            //get width and height of screen
            const width = this.scale.width;
            const height = this.scale.height;

            //border of screen
            const border = this.matter.add.rectangle(width / 2, height, width, 64, {
                isStatic: true,
                render: {
                    visible: false
                }
            }
            );
            //border of screen all sides
            const border2 = this.matter.add.rectangle(width / 2, 0, width, 64, {
                isStatic: true,
                render: {
                    visible: false
                }
            }
            );
            const border3 = this.matter.add.rectangle(0, height / 2, 64, height, {
                isStatic: true,
                render: {
                    visible: false
                }
            }
            );
            const border4 = this.matter.add.rectangle(width, height / 2, 64, height, {
                isStatic: true,
                render: {
                    visible: false
                }

            }
            );

            // Enables the physics world debugger, so you can visualize the physics bodies.
            //this.matter.world.createDebugGraphic();

            // Creates a rectangular body with the given width and height.
            //collision with edges of screen

            // Creates a circular body with the given radius.
           // const circle = this.matter.add.circle(200, 100, 100, { restitution: 0.9 });
            //add circle with png image
            let rand = Math.floor(Math.random() * images.length);
            if (rand <= 2) {
                const ball = this.matter.add.image(30, 30, images[Math.floor(rand)])
                    .setScale(0.1).setInteractive(
                        { draggable: true }
                    );
                ball.setCircle(50);
                ball.setBounce(0.2);
                ball.setFriction(0.005);
                ball.setMass(1);
                ball.setVelocity(0, 0);
                ball.setAngularVelocity(0);
                ball.setFrictionAir(0.01);
                ball.setFrictionStatic(0.5);
                //add ball to group
                group.add(ball);
            } else if (rand >= 3) {
                const ball2 = this.matter.add.image(30, 30, images[Math.floor(rand)])
                    .setScale(0.05).setInteractive(
                        { draggable: true }
                    );
                ball2.setCircle(50);
                ball2.setBounce(0.2);
                ball2.setFriction(0.005);
                ball2.setMass(1);
                ball2.setVelocity(0, 0);
                ball2.setAngularVelocity(0);
                ball2.setFrictionAir(0.01);
                ball2.setFrictionStatic(0.5);
                //add ball to group
                group.add(ball2);
            }


            // Set the ball's properties (optional)
            //max size of ball





            // Creates a constraint that ties two bodies together



            // mouse click  and drag object
            this.input.on('pointerdown', function (pointer, gameObject) {
                if (pointer.leftButtonDown()) {
                    //drag object
                    var drag = this.matter.add.mouseSpring();
                    //stiffness of spring
                    drag.stiffness = 0.4;






                }
            }, this);

            //add ANOTHER ball on right click
            this.input.on('pointerdown', function (pointer) {
                rand = Math.floor(Math.random() * images.length);
                if (pointer.rightButtonDown()) {
                    if (rand <= 2) {
                        const ball = this.matter.add.image(30, 30, images[Math.floor(rand)])
                            .setScale(0.1).setInteractive(
                                { draggable: true }
                            );
                        ball.setCircle(50);
                        ball.setBounce(0.2);
                        ball.setFriction(0.005);
                        ball.setMass(1);
                        ball.setVelocity(0, 0);
                        ball.setAngularVelocity(0);
                        ball.setFrictionAir(0.01);
                        ball.setFrictionStatic(0.5);
                        //add ball to group
                        group.add(ball);
                    } else if (rand >= 3) {
                        const ball2 = this.matter.add.image(30, 30, images[Math.floor(rand)])
                            .setScale(0.05).setInteractive(
                                { draggable: true }
                            );
                        ball2.setCircle(50);
                        ball2.setBounce(0.2);
                        ball2.setFriction(0.005);
                        ball2.setMass(1);
                        ball2.setVelocity(0, 0);
                        ball2.setAngularVelocity(0);
                        ball2.setFrictionAir(0.01);
                        ball2.setFrictionStatic(0.5);
                        //add ball to group
                        group.add(ball2);
                    }




                }
            }, this);

            //spacebar to jump all objects in random direction
            this.input.keyboard.on('keydown-SPACE', function (event) {

                //get all objects
                var objects = group.getChildren();
                //iterate through all objects
                for (var i = 0; i < objects.length; i++) {
                    //get random x and y velocity
                    var x = Phaser.Math.Between(-100, 100);
                    var y = Phaser.Math.Between(-100, 100);
                    //set velocity
                    objects[i].setVelocity(x, y);

                }

            });
        }


        function update() {
            // Update game objects here

            //substeps

            // return ball to original position if it goes off screen
            var objects = this.children.getChildren();
            for (var i = 0; i < objects.length; i++) {
                if (objects[i].x > window.innerWidth || objects[i].x < 0 || objects[i].y > window.innerHeight || objects[i].y < 0) {
                    objects[i].x = 400;
                    objects[i].y = 100;
                    objects[i].setVelocity(0, 0);
                    objects[i].setAngularVelocity(0);
                }
            }


        }

        return () => {
            game.destroy(true);
        };
    }, []);

    return <div id="phaser-container" />;
};

export default PhaserComponent;